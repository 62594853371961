<template>
  <div class="whatsapp-qr _sleep">
    <div class="whatsapp-qr__code">
      <QrcodeVue value="Как вы это отсканировали?)" :size="320"></QrcodeVue>
    </div>

    <div class="whatsapp-qr__action">
      <button class="btn _green1 _small">Получить QR код</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import QrcodeVue from "qrcode.vue";
import { computed } from "vue";

const store = useStore();

type codeStatuses = "sleep" | "success" | "loading" | "error";

const qrStatus = computed<codeStatuses>(() => {
  return "sleep";
});
</script>

<style lang="scss" scoped>
.whatsapp-qr {
  position: relative;

  &__action {
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    .btn._small {
      padding: 15px 17px;
    }
  }

  &._sleep {
    .whatsapp-qr__code {
      opacity: 0.2;
    }
    .whatsapp-qr__action {
      background: rgba(157, 157, 157, 0.4);
      backdrop-filter: blur(5px);
      box-shadow: 0 0 15px rgba(51,51,51, .7);
    }
  }
}
</style>
