import defaultSegments from "@/assets/defaultSegments";
import {
  ISegmentsModule,
  typeNameSegment,
  TypeModifySegments,
  TypeSegment,
  errorText
} from "./segmentsModuleTypes";
import axios, { AxiosResponse, AxiosError } from "axios";
import { Module } from "vuex";
import months from "@/assets/months";
import recomednationModule from "./recomednationsModule";
import clientsModule from "./clientsModule";

function calcoutflowPredictedIncome(segment: TypeSegment): number | null {
  if (segment.average_check) {
    return +segment.total_clients * +segment.average_check;
  }
  return null;
}

const segmentsModule: Module<ISegmentsModule, any> = {
  namespaced: true,
  state: {
    predictedIncomeShow: 'off',
    company_rfm: null,
    modifySegments: null,
    showSegmentSettings: false,
    currentSettingSegment: null,
    currentSelectSegment: null,
    lastUpdateStr: "Загрузка",
    isTableLoading: true,
    tableError: null,
    animationFinallySegmentsTable: false
  },
  mutations: {
    modifyAndSetSegments(state, company_rfm) {
      const expect_segments = company_rfm.expect_segments;
      const modifyDymanicSegments: TypeSegment[] =
        defaultSegments.defaultDymanic.map((segment) => {
          const segmentKey: typeNameSegment | string = segment.key;
          return {
            ...segment,
            ...company_rfm.segments[segmentKey],
            predicted_income: calcoutflowPredictedIncome(
              company_rfm.segments[segmentKey]
            ),
          };
        });

      const modifyAwaitingSegments: TypeSegment[] =
        defaultSegments.defaultAwaiting.map((segment) => {
          const segmentKey: typeNameSegment | string = segment.key;
          return { ...segment, ...expect_segments.segments[segmentKey] };
        });

      const awaitSegment: TypeSegment = { ...defaultSegments.awaitSegment };
      awaitSegment.check = expect_segments.check;
      awaitSegment.total_clients_percent =
        expect_segments.total_expected_clients_percent;
      delete awaitSegment.average_check;
      delete awaitSegment.average_check;
      awaitSegment.total_clients = expect_segments.total_expected_clients;

      const outflow = company_rfm.segments.outflow;

      const modifySegments: TypeModifySegments = {
        outflow: {
          ...outflow,
          ...defaultSegments.outflow,
          key: "outflow",
          visits: "Более одного визита.",
          predicted_income: calcoutflowPredictedIncome(outflow),
        },
        without_orders: {
          ...defaultSegments.without_orders,
          ...company_rfm.segments.without_orders,
          name: "Клиенты без визитов",
          predicted_income: calcoutflowPredictedIncome(
            company_rfm.segments.without_orders
          ),
        },
        dynamicSegments: modifyDymanicSegments,
        awaitingSegments: modifyAwaitingSegments,
        awaitSegment: awaitSegment,
        hadVisits: {
          average_check: company_rfm.s123_average_check,
          total_clients_percent: company_rfm.s123_percent,
          total_clients: company_rfm.total_s123_clients,
          ...defaultSegments.had_come,
          
          predicted_income: +company_rfm.s123_average_check * +company_rfm.total_s123_clients
        },
        black_list: defaultSegments.black_list,
      };

      state.modifySegments = modifySegments;
      state.company_rfm = company_rfm;

      if (state.tableError === 'Нет данных о компании') {
        state.animationFinallySegmentsTable = true
      }
    },
    setCurrentSegment(
      state,
      params: { current: TypeSegment | null; add: TypeSegment | null }
    ) {
      state.currentSettingSegment = params.current;
      state.currentSelectSegment = params.add;
    },
  },
  getters: {},
  actions: {
    async getSegmentsTable(context) {
      const url = `https://rfm.botzavod.com/api/rfm-company`;

      return await axios({
        method: "GET",
        url: url,
        params: {
          salon_id: context.rootState.salon_id
        },

        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((res: AxiosResponse) => {
        console.log(res.data.company_rfm);
        const now = new Date();

        const day = now.getDate();
        const month = months[now.getMonth()].short;
        const year = now.getFullYear();
        const hours = now.getHours().toString().padStart(2, "0");
        const minutes = now.getMinutes().toString().padStart(2, "0");
        const dateTimeString = `${day} ${month} ${year} - ${hours}:${minutes}`;
        context.state.lastUpdateStr = `Последнее обновление <span class="fw-600">${dateTimeString}</span>`;
        context.state.tableError = null
        context.commit("modifyAndSetSegments", res.data.company_rfm);
        context.state.isTableLoading = false
      }).catch((error:AxiosError<{description:errorText}>) => {
        const errorDescr = error?.response?.data?.description
        if (errorDescr) {
          context.state.tableError = errorDescr

          if (errorDescr === 'Ошибка сбора клиентов. Обратитесь в поддержку!') {
            context.state.lastUpdateStr = 'Произошла ошибка - обратитесь в поддержку '
          } else if (errorDescr === 'Нет данных о компании' || errorDescr === "Нет rfm данных о компании") {
            context.state.lastUpdateStr = 'Собираем данные для анализа'
          }
        }
      })
    },
  },
  modules: {
    recomednation: recomednationModule,
    clients: clientsModule,
  },
};

export default segmentsModule;
