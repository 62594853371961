<template>
  <div :class="isOpen ? '_open' : ''">
    <div @click="toggle" style="cursor: pointer">
      <slot name="header"></slot>
    </div>
    <div :style="`height: ${isOpen ? props.height : 0}px;`" class="content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";

/* eslint-disable-next-line */
const props = defineProps({
  height: Number,
  defaultOpen: Boolean,
});

const isOpen = ref(props.defaultOpen || false);

function toggle() {
  isOpen.value = !isOpen.value;
}
</script>

<style scoped>
.content {
  overflow: hidden;
  transition: 0.2s;
}
</style>
