<template>
  <div class="last-update">
    <div class="last-update__timer" :style="`background-color:${bgTimer}`">
      <span v-if="timerVisible">{{ intervalValue }}</span>
    </div>
    <div
      class="last-update__str"
      v-html="$store.state.segments.lastUpdateStr"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { errorText } from "@/store/modules/segments/segmentsModuleTypes";
import { computed } from "vue";
import { useStore } from "vuex";

/* eslint-disable-next-line */
const props = defineProps({
  intervalValue: Number
})

const store = useStore();

const tableError = computed<errorText>(() => store.state.segments.tableError)

const bgTimer = computed(() => tableError.value !== 'Ошибка сбора клиентов. Обратитесь в поддержку!' ? '#fbcd00' : '#FF4141');
const timerVisible = computed(() => tableError.value === null)
</script>

<style lang="scss" scoped>
.last-update {
  align-self: flex-start;

  display: inline-flex;
  align-items: center;
  gap: 4px;

  height: 27px;
  min-width: 266px;

  font-size: 10px;

  margin-bottom: 9px;

  border: solid 1px #cccccc;
  border-radius: 9px;
  padding: 2px;
  padding-right: 5px;

  &__str {
    display: inline-block;

    text-align: right;
    line-height: 20px;
    padding-top: 2px;
  }
  &__timer {
    
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    width: 35px;
    height: 100%;
    border-radius: 6px;
    line-height: 2;

    span {
      transform: translateY(0.5px);
    }
  }
}
</style>
