<template>
  <div v-show="!showSegmentSettings">
    <div class="app-header">
      <lastUpdateTable :intervalValue="intervalValue"></lastUpdateTable>
      <searchClientByPhone
        @openSegment="(index) => openSegmentByIndex(index)"
      ></searchClientByPhone>
    </div>

    <div class="segments-table" v-if="$store.state.segments.modifySegments">
      <div class="segments-header">
        <div class="segments-header__border">
          <div
            class="segments-header__info _all base-segment _border-radius"
            :style="`background-color: #fff; border: solid 2px ${without_orders.border_color}`"
          >
            <div>Всего клиентов в базе</div>
            <div
              class="base-segment__price"
              :class="{ 'loading-text': isTableLoading }"
            >
              <span class="fw-600">{{ allClients.toLocaleString() }}</span>
              — 100%
            </div>
          </div>
          <div class="segments-header__gap">—</div>
          <div
            @click="setCurrentSegment(without_orders, without_orders)"
            :class="
              isTableLoading ? '' : '_hover _hoverBorderPlus _border-radius'
            "
            class="segments-header__info _all base-segment"
            :style="`background-color: #D9D9D9; border: solid 2px #CCCCCC; min-width: 256px`"
          >
            <!-- width:${segment6Width+.9}px -->
            <div>Клиенты без визитов</div>

            <div
              class="base-segment__price"
              :class="{ 'loading-text': isTableLoading }"
            >
              <span class="fw-600">{{
                (+without_orders.total_clients).toLocaleString()
              }}</span>
              — {{ (+without_orders.total_clients_percent).toLocaleString() }}%
            </div>
          </div>
          <div class="segments-header__gap"><span>—</span><span>—</span></div>

          <div
            class="segments-header__info _all base-segment"
            :style="`background-color: #fff; border: solid 2px #ABE9DC; min-width: 270px`"
          >
            <!-- width:${segment6Width+.9}px -->
            <div>Сегментировано клиентов</div>
            <div
              class="base-segment__price"
              :class="{ 'loading-text': isTableLoading }"
            >
              <span class="fw-600">{{ segmentClients.toLocaleString() }}</span>
              —
              {{
                (100 - +without_orders.total_clients_percent).toLocaleString()
              }}%
            </div>
          </div>
        </div>

        <div
          class="segments-header__toggle"
          :class="{ '_in-develop': isTableLoading }"
          :style="`width: 301px`"
        >
          <label
            class="segments-header__label"
            :class="predictedIncomeShow === 'off' ? 'fw-600' : ''"
            for="predictedIncomeShowRadioOn"
          >
            <inputTypeRadio
              v-model="store.state.segments.predictedIncomeShow"
              name="predictedIncomeShowRadio"
              value="off"
              id="predictedIncomeShowRadioOn"
            ></inputTypeRadio>
            <span>Средний чек</span>
          </label>
          <label
            class="segments-header__label"
            :class="predictedIncomeShow === 'on' ? 'fw-600' : ''"
            for="predictedIncomeShowRadioOff"
          >
            <inputTypeRadio
              v-model="store.state.segments.predictedIncomeShow"
              name="predictedIncomeShowRadio"
              value="on"
              id="predictedIncomeShowRadioOff"
            ></inputTypeRadio>
            <span>Потенциал выручки</span>
          </label>
        </div>
        <div class="header-legal" :class="{ '_in-develop': isTableLoading }">
          <div class="header-legal__row">
            <span class="fw-600">Легальность клиентской базы</span>
            <span class="fw-600">?? %</span>
          </div>
          <div class="header-legal__row">
            <span class="text-small">
              Клиент явно дал согласие на отправку информационно - рекламной
              рассылки
            </span>
            <inputSwitch></inputSwitch>
          </div>
        </div>
      </div>
      <div class="awaiting-segments">
        <div class="awaiting-segments__list">
          <div
            @click="setAwaitSegment(segment)"
            class="awaiting-segments__item base-segment"
            :class="{
              _hover: !isTableLoading,
              '_border-top-radius': number === 0,
              '_border-bottom-left-radius': number === 2,
            }"
            v-for="(segment, number) in awaitngSegments"
            :key="segment.index"
            :style="`background-color:${segment.background_color}; height: ${
              (segment.max_frequency - segment.min_frequency) * 20
            }%`"
          >
            <div>{{ segment.name }}</div>
            <div
              class="awaiting-segments__stats fs-18px"
              :class="{ 'loading-text': isTableLoading }"
            >
              <span class="fw-600">
                {{ segment.total_clients }}
              </span>
              —
              {{ segment.total_clients_percent }}%
            </div>
            <div>На сумму</div>
            <div
              class="segments-item__check fw-600 fs-18px"
              :class="{ 'loading-text': isTableLoading }"
            >
              {{ (+segment.check).toLocaleString() }} ₽
            </div>
          </div>
        </div>

        <div class="awaiting-segments__title fw-600">Зона ожидания</div>

        <baseSegment
          class="awaiting-segments__all"
          :class="{ '_hover _border-radius': !isTableLoading }"
          :segment="awaitSegment"
          @click="setCurrentSegment(awaitSegment, awaitSegment)"
        >
          <div class="base-segment__name mt-11px">Оперативные записи</div>
          <div
            class="base-segment__stats"
            :class="{ 'loading-text': isTableLoading }"
          >
            <span class="fw-500">XXX</span> – XXX
          </div>
        </baseSegment>
      </div>
      <div class="dynamic-segments">
        <div class="dynamic-segments__table">
          <div class="graph-y">
            <span v-for="num in graphY" :key="num">{{ num }}</span>
          </div>
          <div class="dynamic-segments__list">
            <segmentsItem
              v-for="(segment, index) in segmentsArray"
              :key="segment.id"
              :segment="segment"
              :class="`segment-${index}`"
              @click="setCurrentSegment(segment, segment)"
            ></segmentsItem>
            <span class="graph-line _0"></span>
            <span class="graph-line _1"></span>
            <span class="graph-line _2"></span>
            <span class="graph-line _3"></span>
          </div>

          <baseSegment
            :segment="outflow"
            @click="setCurrentSegment(outflow, outflow)"
            :class="{ '_hover _border-radius': !isTableLoading }"
            class="segment-outflow"
          ></baseSegment>
          <div class="dynamic-segments__title fw-600">
            После визита

            <div class="graph-x">
              <span v-for="num in graphX" :key="num">{{ num }}</span>
            </div>
          </div>
          <baseSegment
            :style="`width:${segment6Width - 0.3}px`"
            :segment="hadVisits"
            @click="setCurrentSegment(hadVisits, hadVisits)"
            :class="{ '_hover ': !isTableLoading }"
            class="segment-hadVisits _border-radius"
          ></baseSegment>

          <baseSegment
            :style="`width: 199px;color: #fff; transform: translateX(4px);`"
            :segment="black_list"
            :class="{ _hover: !isTableLoading }"
            class="segment-backList _border-radius"
          ></baseSegment>
        </div>
      </div>
    </div>
  </div>

  <segmentsMenu v-if="showSegmentSettings"></segmentsMenu>

  <a
    v-if="!$store.state.segments.currentSettingSegment"
    class="btn _opacity"
    href="https://b24-z5t4c9.bitrix24site.ru/crm_form_1wpfx/"
    target="_blank"
    >заявка на доработку</a
  >

  <div class="confetti" v-if="animationFinallySegmentsTable">
    <img src="~@/assets/images/confettti.gif" />
    <img src="~@/assets/images/confetti-glitter.gif" />
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import segmentsItem from "./segmentsItem.vue";
import baseSegment from "./baseSegment.vue";
import segmentsMenu from "./segmentsMenu.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import inputSwitch from "@/components/UI/inputs/input-switch.vue";
import lastUpdateTable from "./lastUpdateTable.vue";
import searchClientByPhone from "./searchClientByPhone.vue";
import { computed, onMounted, onUnmounted, onUpdated, ref, watch } from "vue";

const store = useStore();

const company_rfm = computed(() => store.state.segments.company_rfm);
const modifySegments = computed(() => store.state.segments.modifySegments);

const isTableLoading = computed(() => store.state.segments.isTableLoading);

const predictedIncomeShow = computed(
  () => store.state.segments.predictedIncomeShow
);

const allClients = computed(() => +company_rfm.value.total_clients);
const segmentClients = computed(() => +company_rfm.value.segment_clients);
const averageCheck = computed(() => +company_rfm.value.average_check);

const expectSegments = computed(() => company_rfm.value.expect_segments);
const allExeptClients = computed(
  () => +expectSegments.value.total_expected_clients
);
const averageCheckExpet = computed(() => +expectSegments.value.check);

const graphX = computed(() => {
  const levelsArr = [
    +company_rfm.value.r1_level,
    +company_rfm.value.r2_level,
    +company_rfm.value.r3_level,
    +company_rfm.value.r4_level,
  ];

  const floorArr = levelsArr.map((i) => Math.floor(i));

  return floorArr;
});
const graphY = ["4+", 3, 2, 1, 0];

const outflow = computed(() => store.state.segments.modifySegments.outflow);
const hadVisits = computed(() => store.state.segments.modifySegments.hadVisits);
const black_list = computed(
  () => store.state.segments.modifySegments.black_list
);
const without_orders = computed(
  () => store.state.segments.modifySegments.without_orders
);
const didNotCome = computed(
  () => store.state.segments.modifySegments.didNotCome
);

const firstVertikalPos = (5 / 3) * 1;
const secondVertikalPos = (5 / 3) * 2;

const awaitngSegments = computed(
  () => store.state.segments.modifySegments.awaitingSegments
);

const segmentsArray = computed(
  () => store.state.segments.modifySegments.dynamicSegments
);

const showSegmentSettings = computed({
  get: () => store.state.segments.showSegmentSettings,
  set: (newValue) => (store.state.segments.showSegmentSettings = newValue),
});

function openSegmentByIndex(index) {
  if (!isTableLoading.value) {
    const modifySegments = store.state.segments.modifySegments;
    const allSegments = [
      modifySegments.outflow,
      modifySegments.without_orders,
      ...modifySegments.dynamicSegments,
      ...modifySegments.awaitingSegments,
      modifySegments.hadVisits,
      modifySegments.black_list,
    ];

    const segment = allSegments.find((seg) => seg.index === index);

    if (segment) {
      const currentSegment = modifySegments.awaitingSegments.includes(segment)
        ? modifySegments.awaitSegment
        : segment;
      store.commit("segments/setCurrentSegment", {
        current: currentSegment,
        add: segment,
      });
      showSegmentSettings.value = true;
      window.scrollTo(0, 0);
      store.commit("reactivation/resetCreateReactivation");
    } else {
      console.error(`Segment with index ${index} not found.`);
    }
  }
}

function setCurrentSegment(segment, add) {
  if (!isTableLoading.value) {
    store.commit("segments/setCurrentSegment", {
      current: segment,
      add: add,
    });
    showSegmentSettings.value = true;
    window.scrollTo(0, 0);
    store.commit("reactivation/resetCreateReactivation");
  }
}
const awaitSegment = computed(
  () => store.state.segments.modifySegments.awaitSegment
);

function setAwaitSegment(segment) {
  setCurrentSegment(awaitSegment.value, segment);
}

let intervalId: number | undefined;
let timerIntervalId: number | undefined;
let intervalValue = ref(60);

intervalValue.value = 60;
clearInterval(timerIntervalId);

timerIntervalId = setInterval(() => {
  intervalValue.value--;
}, 1000);

const segment6Width = ref(0);
const segment4Width = ref(0);
const updateSegmentWidth = () => {
  const segment6 = document.querySelector(".segment-6");
  const segment4 = document.querySelector(".segment-4");
  if (segment6) {
    segment6Width.value = segment6.offsetWidth;
  }
  if (segment4) {
    segment4Width.value = segment4.offsetWidth;
  }
};
onMounted(() => {
  intervalId = setInterval(() => {
    if (store.state.auth.bearerToken) {
      store.dispatch("segments/getSegmentsTable");

      intervalValue.value = 60;
      clearInterval(timerIntervalId);

      timerIntervalId = setInterval(() => {
        intervalValue.value--;
      }, 1000);
    }
  }, 60000);

  updateSegmentWidth();
  // Можно добавить слушатель на изменение размера окна, если нужно
  setTimeout(() => {
    updateSegmentWidth();
    window.addEventListener("resize", updateSegmentWidth);
  }, 500);
});

onUpdated(() => {
  updateSegmentWidth();
});

onUnmounted(() => {
  if (intervalId !== undefined) {
    clearInterval(intervalId); // Очистка интервала при размонтировании компонента
  }
});

const animationFinallySegmentsTable = computed({
  get: () => {
    return store.state.segments.animationFinallySegmentsTable;
  },
  set(newValue) {
    store.state.segments.animationFinallySegmentsTable = newValue;
  },
});
watch(animationFinallySegmentsTable, (newValue) => {
  if (newValue === true) {
    setTimeout(() => {
      animationFinallySegmentsTable.value = false;
    }, 2000);
  }
});
</script>

<style lang="scss" scoped>
$border-color: #cccccc;

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-right: 4px;
}

.confetti {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50vh;

  z-index: 999;

  img {
    position: absolute;
  }
  img:nth-child(1) {
    width: 100%;
    height: 100vh;
    max-width: 1600px;
    top: 100px;
  }
  img:nth-child(2) {
    width: 400px;
    filter: hue-rotate(149deg);
    transform: scaleX(-1);

    right: 0;
    top: 100%;
  }
  img:nth-child(3) {
    width: 400px;
    transform: scaleX(-1);

    right: 40%;
    top: 20%;
  }
}

.segments-table {
  width: 100%;
  max-width: 1600px;
  display: grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 266px minmax(auto, 1450px);
  row-gap: 23px;
}
.segments-header {
  grid-row: span 1;
  grid-column: span 2;
  display: flex;
  gap: 10.9px;
  align-items: center;

  &__gap {
    width: 38px;
    display: flex;
    flex-direction: column;
    line-height: 6px;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  &__toggle {
    padding-left: 28px;
    border-radius: 12px;
    border: 1px solid #ccc;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
  &__label {
    display: flex;
    font-size: 16px;
    align-items: center;
    gap: 11px;
    cursor: pointer;
  }
  .header-legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    border-radius: 12px;
    margin-right: 4px;

    border: 1px solid #dd6068;
    height: 100%;
    gap: 12px;
    flex-grow: 1;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      .fw-600:last-child {
        font-size: 20px;
      }
    }
    .text-small {
      font-weight: 500;
      font-size: 10px;
    }
  }

  &__border {
    border: solid 1px $border-color;
    display: flex;
    border-radius: 15px;

    padding: 5px 4.6px 5px 5px;

    .base-segment {
      &:first-child {
        width: 255px;
      }
      &:nth-child(2) {
      }
    }
  }
  &__info {
    border-radius: 12px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 16px;
    max-width: 506px;
    height: 91px;

    &._segmenting {
      width: 517px;
    }

    &._all {
      background: #fed47a;
      grid-template-columns: 1fr;
    }
  }
}
.dynamic-segments {
  position: relative;
  width: 100%;
  border-radius: 12px;

  &__title {
    padding-left: 19px;
    grid-row: span 2;
    grid-column: span 1;

    display: flex;
    align-items: center;
  }
  &__col {
    display: grid;
    grid-row-gap: 6px;
  }

  &__list {
    grid-row: span 1;
    position: relative;
    height: 608px;

    .segment-6:after {
      content: "";
      position: absolute;
      pointer-events: none;
      z-index: 999;
      top: -5.7px;
      left: -5.88px;
      right: -5px;
      width: calc(100% + 12px);
      height: 828px;
      border: solid 1px $border-color;
      border-radius: 15px 0 15px 15px;
      display: block;
    }
  }

  .graph-y {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #a1a2a2;
    align-items: center;
    grid-row: span 2;
    font-size: 16px;
    font-weight: 400;
    height: 81.5%;
    width: 84%;
    margin-top: 90px;

    span {
      height: 11.5px;
    }
  }
  .graph-x {
    flex-grow: 1;
    position: relative;
    color: #a1a2a2;
    font-size: 16px;
    font-weight: 400;

    span {
      top: -9px;
      position: absolute;
      &:nth-child(1) {
        left: 23.9%;
      }
      &:nth-child(2) {
        left: 51%;
      }
      &:nth-child(3) {
        left: 76%;
      }
      &:nth-child(4) {
        left: 101%;
      }
    }
  }

  &__table {
    display: grid;
    grid-template-columns: 33px auto 205px;
    grid-template-rows: auto 35px;
    align-items: end;
    grid-column: span 2;

    align-items: center;
  }
  .segment-outflow {
    height: 100%;
    border-radius: 15px;
    border: solid 4px #fff;
  }

  .segment-hadVisits {
    grid-row: span 3;
    grid-column: 2;
    margin-left: 0px;
  }
}
.awaiting-segments {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid 1px $border-color;
  border-radius: 15px;
  padding: 5px 5.5px 5px 5px;

  &__title {
    padding-left: 3px;
    padding-bottom: 5px;
    padding-left: 18px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 608px;
  }

  &__item {
    padding-left: 20px !important;

    margin-bottom: 9.9px;

    display: grid;
    grid-template-rows: 1fr 2fr 1fr 2fr;
    row-gap: 10px;

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &:last-child {
      border-bottom-left-radius: 12px;
    }
  }

  &__all {
    border-radius: 12px;
  }
}
.negative-segments {
  grid-column: span 2;
  display: grid;
  width: 100%;
  grid-template-columns: minmax(auto, 271px) minmax(auto, 374px);
  grid-column-gap: 36px;

  .base-segment {
    border-radius: 12px;
  }
}
.btn {
  width: 100%;
  margin-top: 101px;
  align-self: flex-start;
}

.graph-line {
  display: block;
  position: absolute;
  left: 0;
  height: 100%;
  width: 0.5px;
  background: $border-color;
  z-index: 999;
  bottom: -16px;

  &:after {
    content: ".";
    position: absolute;
    bottom: -9px;
    left: -5.6px;
    font-size: 40px;
    color: $border-color;
  }
  &._0 {
    left: 32.1%;
    background: transparent;
    &:after {
      left: -10.2px;
    }
  }
  $col1Width: 32.1875%;
  $col2Width: 24.0625%;
  $col3Width: 21.875%;
  $col4Width: $col3Width;

  &._1 {
    left: calc($col1Width + $col2Width - 5.5px);
    height: calc(66% + 8px);
    &:after {
      left: -5.6px;
    }
  }
  &._2 {
    height: calc(33% + 8px);
    left: calc($col1Width + $col2Width + $col3Width - 5.9px);

    &:after {
      left: -5.6px;
    }
  }
  &._3 {
    left: calc($col1Width + $col2Width + $col3Width + $col4Width - 3.9px);
    &:after {
      left: -5.6px;
    }
  }
}
</style>
