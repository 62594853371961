<template>
  <div
    class="reactivation-list"
    v-if="reactivationList && reactivationList.find((i) => i.goal)"
  >
    <div class="reactivation-list__row">
      <div class="reactivation-list__col">Статус</div>
      <div class="reactivation-list__col">Название</div>
      <div class="reactivation-list__col">Записался</div>
      <div class="reactivation-list__col">Пришел</div>
      <div class="reactivation-list__col">Оплатил</div>
      <div class="reactivation-list__col">Средний чек</div>
      <div class="reactivation-list__col">Выручка</div>
    </div>
    <div
      class="reactivation-list__row"
      :class="activation.open ? `_open` : ''"
      v-for="activation in reactivationList"
      :key="activation.id"
    >
      <div class="reactivation-list__col">{{ getStatus(activation) }}</div>
      <div class="reactivation-list__col">{{ activation.name }}</div>
      <div class="reactivation-list__col">
        {{ getOrderClients(activation) }}
      </div>
      <div class="reactivation-list__col">{{ getCameClients(activation) }}</div>
      <div class="reactivation-list__col">
        {{ getPaidClient(activation) }}
      </div>
      <div class="reactivation-list__col">
        {{
          activation.goal.average_check
            ? activation.goal.average_check.toLocaleString() + "₽"
            : "-"
        }}
      </div>
      <div class="reactivation-list__col">
        {{
          activation.goal.revenue
            ? activation.goal.revenue.toLocaleString() + "₽"
            : "-"
        }}
      </div>
      <div class="reactivation-list__col">
        <button
          class="reactivation-list__btn"
          @click="toggleReactivation(activation.id)"
        >
          <img :src="require('@/assets/images/reactivation-open.svg')" alt="" />
        </button>
      </div>

      <div
        class="reactivation-list__content reactivation-info"
        :data-id="activation.id"
      >
        <div class="reactivation-info__header">
          <div class="reactivation-info__col">Параметры Кампании</div>
          <div class="reactivation-info__col">
            <span v-if="!activation.goal.ignore_stop"
              >Включить в Реактивацию всех клиентов сегмента</span
            >
            <span v-else
              >Исключмить клиентов, которые не дали согласие на отправку
            </span>
            <span>Всем отправить по {{ getChannel(activation) }}</span>
          </div>
          <div class="reactivation-info__col">
            <span>Цель кампанании - Клиент пришёл и оплатил</span>
            <span></span>
          </div>
        </div>
        <div
          class="reactivation-info__params"
          v-if="getIsShowParams(activation)"
        >
          <div
            class="reactivation-info__row"
            v-if="store.state.auth.isShowWhatsApp"
          >
            <span>Кампания закончится через</span>
            <span>0 клиентов</span>
            <span>0 день и 0 часов </span>
          </div>
          <div class="reactivation-info__row">
            <span>Отслеживание цели</span>
            <span>{{getTotalClientsStr(activation)}}</span>
            <span>{{getTimeRemaining(activation.goal.finish_date)}}</span>
          </div>
        </div>
        <div
          class="reactivation-info_actions"
          v-if="getIsShowParams(activation)"
        >
          <button
            class="btn _small _green"
            v-if="store.state.auth.isShowWhatsApp && false"
          >
            Возобновить рассылку
          </button>
          <button
            class="btn _small"
            v-else-if="store.state.auth.isShowWhatsApp"
          >
            Остановить рассылку
          </button>
          <button
            class="btn _small _red"
            @click="
              store.dispatch(
                'reactivation/stopWatchingReactivation',
                activation.id
              )
            "
          >
            Завершить досрочно
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

const reactivationList = computed(
  () => store.state.reactivation.reactivationList
);

function toggleReactivation(id) {
  reactivationList.value.forEach((element) => {
    const content = document.querySelector(
      `.reactivation-list__content[data-id="${element.id}"]`
    );
    if (element.id !== id) {
      element.open = false;
      if (content) {
        content.style.maxHeight = "0";
      }
    } else {
      element.open = !element.open;
      if (content) {
        content.style.maxHeight = element.open
          ? `${content.scrollHeight}px`
          : "0";
      }
    }
  });
}

function getStatus(activation) {
  return activation.goal.description;
}
function formatNumber(value) {
  return Math.trunc(value).toString();
}

function getClientsInfo(activation, countKey, percentKey) {
  if (typeof activation.goal[countKey] === "number") {
    const countValue = formatNumber(activation.goal[countKey]);
    const percentValue = activation.goal[percentKey]
      ? formatNumber(activation.goal[percentKey]) + "%"
      : "0%";
    return countValue + " / " + percentValue;
  } else {
    return "- / -";
  }
}

function getPluralForm(number, forms) {
const n = Math.abs(number) % 100;
const n1 = n % 10;
if (n > 10 && n < 20) {
    return forms[2];
}
if (n1 > 1 && n1 < 5) {
    return forms[1];
}
if (n1 === 1) {
    return forms[0];
}
return forms[2];
}

function getTotalClientsStr(activation) {
  const totalClients = activation.goal.total_clients
  return `${totalClients} ${getPluralForm(totalClients, ['клиент', 'клиента', 'клиентов'])}`
}

function getTimeRemaining(targetDateStr) {
const targetDate = new Date(targetDateStr);
const currentDate = new Date();

const diffInMilliseconds = targetDate - currentDate;

if (diffInMilliseconds <= 0) {
    return "Дата уже прошла";
}

const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
const days = Math.floor(diffInHours / 24);
const hours = diffInHours % 24;

const dayForms = ["день", "дня", "дней"];
const hourForms = ["час", "часа", "часов"];

const dayString = `${days} ${getPluralForm(days, dayForms)}`;
const hourString = `${hours} ${getPluralForm(hours, hourForms)}`;

return `${dayString} ${hourString}`;
}


function getIsShowParams(activation) {
  return [1, 2, -1].includes(activation.goal.is_active);
}

function getOrderClients(activation) {
  return getClientsInfo(
    activation,
    "signed_up_clients_count",
    "signed_up_clients_percent"
  );
}

function getCameClients(activation) {
  return getClientsInfo(
    activation,
    "came_clients_count",
    "came_clients_percent"
  );
}

function getPaidClient(activation) {
  return getClientsInfo(
    activation,
    "paid_clients_count",
    "paid_clients_percent"
  );
}

function getChannel(activation) {
  return activation.channel[0] === "SMS" ? "WhatsApp" : "Email";
}

const store = useStore();
</script>

<style lang="scss" scoped>
.reactivation-list {
  max-width: 1384px;
  border: solid 2px #e5e7ed;
  border-radius: 20px;

  &__btn {
    cursor: pointer;
    transform: rotate(0deg);
    transition: 0.2s;
    padding: 20px;
    margin: -20px;
  }
  &__row {
    display: grid;
    grid-template-columns: 1.7fr 9fr 3fr 3fr 2fr 3fr 2fr 0.4fr;
    grid-template-rows: 1fr 0px;
    grid-column-gap: 24px;
    padding: 23px;
    border-bottom: solid 2px #e5e7ed;
    font-size: 16px;
    cursor: default;
    transition: 0.2s;

    &:last-child {
      border-bottom: none;
    }
    &:first-child {
      font-size: 18px;
      font-weight: 500;
    }
  }
  &__content {
    padding-top: 37px;
    overflow: hidden;
    grid-column: 1 / 9;
    max-height: 0;
    transition: max-height 0.3s ease-out;
  }

  &__row._open {
    grid-template-rows: 1fr auto;
  }

  &__row._open &__btn {
    transform: rotate(180deg);
  }

  &__col {
    font-weight: 500;
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(5) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.reactivation-info {
  display: grid;
  grid-template-rows: 1fr 1.2fr;
  grid-template-columns: 2fr 445px;
  row-gap: 29px;
  &_actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 15px;
    column-gap: 23px;
    grid-template-rows: 41px;

    .btn {
      grid-column: 2/3;
      min-height: 41px;
    }
  }

  &__header {
    grid-row: 1;
    grid-column: 1/3;

    display: grid;
    grid-template-columns: 1.3fr 1.7fr 2.3fr 1fr;
    background: #cdf7ff;
    padding: 30px;
    border-radius: 12px;
    font-weight: 500;
  }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    span {
      font-size: 12px;
    }
  }

  &__params {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
  }

  &__row {
    display: flex;

    span:nth-child(1) {
      width: 35%;
    }
    span:nth-child(2) {
      width: 20%;
    }
  }
}
</style>
