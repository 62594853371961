<template>
  <div class='whatsapp-page'>
    <div class="whatsapp-phone">
      <div class="tit-h4 fw-500">Если соединение будет разорвано, <br> мы уведомим вас по номеру на  WhatsApp</div>

      <input type="tel">

      <button class="btn _green1">Сохранить</button>
    </div>

    <whatsAppBlock></whatsAppBlock>
  </div>
</template>

<script lang='ts' setup>
import { useStore } from 'vuex';
import whatsAppBlock from '@/components/whatsapp/whatsappBlock.vue'

const store = useStore();

</script>

<style lang='scss' scoped>
.whatsapp-page {
  width: 100%;
  max-width: 1200px;
}
.whatsapp-phone {
  display: flex;
  gap: 20px;
  align-items: center;
  background: #e0e0df87;
  padding: 40px 29px;
  border-radius: 12px;
  margin-bottom: 30px;

  .tit-h4 {
    line-height: 24px;
  }

  input {
    min-width: 30%;
    min-height: 48px;
    border-radius: 7px;
    font-size: 16px;
    padding: 0 10px;

    &:focus {
      outline: solid 1px rgba(51, 51, 51, 0.338);
    }
  }

  button {
    flex-grow: 1;
  }
}
</style>