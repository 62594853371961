import axios, { AxiosResponse, AxiosError } from "axios";
import { Module } from "vuex";

const defaultReactivationTemplate = `Привет, %CLIENT_NAME%!

Мы только что обновили нашу акцию для постоянных клиентов.
Интересно узнать, что для вас приготовили?

Просто напишите "Да", и я расскажу подробности! 

Хорошо?`

const defaultReactivationFormData = {
  ignoreActiveReactivation: "0",
  ignorePostBan: true,
  channel: "SMS",
  startDate: "",
  startTime: "",
  intervalWhhenLetSending: ["", ""],
  target: "",
  trackDuring: 30,
  finishAfterNextMailing: false,
}

const reactivationModule: Module<any, any> = {
  namespaced: true,
  state: {
    reactivationCreateStep: 0,

    reactivationTestNumber: "",
    reactivationTemplate: defaultReactivationTemplate,

    createReactivationFromData: {...defaultReactivationFormData},

    currentReactivationClientCount: 0,

    reactivationTestLoading: false,
    reactivationTestStatus: "",

    reactivationList: null,
  },
  getters: {
    getNewReactivationDatetime(state) {
      const reactivationFormData = state.createReactivationFromData;
      if (reactivationFormData.startDate && reactivationFormData.startTime) {
        return `${
          reactivationFormData.startTime
        } - ${reactivationFormData.startDate.split("-").reverse().join(".")} `;
      } else {
        return null;
      }
    },
  },
  mutations: {
    updateReactivationTemplate(state, newTemplate) {
      state.reactivationTemplate = newTemplate;
    },
    setReactivationTestNumber(state, newTel) {
      state.reactivationTestNumber = newTel;
    },
    parseGoalsReactivations(state, goals: any) {
      state.reactivationList.forEach((reactivation: any) => {
        const currentGoal = goals.find(
          (i: any) => i.mailing_id === reactivation.id
        );
        if (currentGoal) {
          reactivation.goal = currentGoal;
        }
      });
    },
    resetCreateReactivation(state) {
      state.reactivationTemplate = defaultReactivationTemplate;
      state.createReactivationFromData = {...defaultReactivationFormData}
    }
  },
  actions: {
    async createReactivationTest(context) {
      context.state.reactivationTestLoading = true;
      const data = {
        salon_id: context.rootState.salon_id,
        phone: context.state.reactivationTestNumber,
        channel: context.state.createReactivationFromData.channel,
        text: context.state.reactivationTemplate,
      };
      return await axios({
        method: "POST",
        url: " https://rfm.botzavod.com/api/send-test-mailing",
        data: data,
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
      }).then((data: AxiosResponse) => {
        context.state.reactivationTestLoading = false;
        console.log(data.data);
        context.state.reactivationTestStatus =
          "Тестовое сообщение успешно отправлено";
      });
    },
    async createReactivation(context) {
      context.commit("setMainLoader", true, { root: true });
      const currentReactivationClientCount = context.state.currentReactivationClientCount
      const reactivationFormData = context.state.createReactivationFromData;
      const currentSelectSegment =
        context.rootState.segments.currentSelectSegment;
      const reactivationsName =
        context.getters.getNewReactivationDatetime 
        "| " +
        "Сегмент " +
        currentSelectSegment.name + ' | ' + currentReactivationClientCount + 'Клиентов';
      const startDatetime =
        reactivationFormData.startDate +
        " " +
        reactivationFormData.startTime +
        ":00";
      const ignoreStop = reactivationFormData.ignorePostBan ? 1 : 0;
      const trackDuring = +reactivationFormData.trackDuring;
      const finishAfterNextMailing = reactivationFormData.finishAfterNextMailing
        ? 1
        : 0;

      const ignoreActiveReactivation =
        +reactivationFormData.ignoreActiveReactivation;
      const data = {
        salon_id: context.rootState.salon_id,
        name: reactivationsName,
        segment_id: currentSelectSegment.index,
        channel: reactivationFormData.channel,
        start_date: startDatetime,
        text: context.state.reactivationTemplate,
        ignore_stop: ignoreStop,
        finish_after_days: trackDuring,
        finish_after_next_mailing: finishAfterNextMailing,
        ignore_active_reactivation: ignoreActiveReactivation,
      };

      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/set-mailing",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        data: data,
      }).then((res: AxiosResponse) => {
        console.log(res.data);
        context.commit("setMainLoader", false, { root: true });
        context.state.reactivationCreateStep = 0;
        context.rootState.segments.showSegmentSettings = false;
        context.commit('resetCreateReactivation')
        window.scrollTo(0,0)
        context.dispatch("getReactivations");
      });
    },
    async getReactivations(context) {
      context.commit("setMainLoader", true, { root: true });
      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-company-mailing",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
        },
      })
        .then((res: AxiosResponse) => {
          context.state.reactivationList = res.data.company_mailings;

          context.dispatch("getGoalsReactivations");
          context.commit("setMainLoader", false, { root: true });
        })
        .catch((error: any) => {
          context.commit("setMainLoader", false, { root: true });
          console.log(error);
          if (error?.response?.data?.description === "Рассылки не найдены") {
            context.state.reactivationList = [];
          }
        });
    },
    async getGoalsReactivations(context) {
      return await axios({
        method: "GET",
        url: "https://rfm.botzavod.com/api/get-company-mailing-goals",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        params: {
          salon_id: context.rootState.salon_id,
        },
      })
        .then((res: AxiosResponse) => {
          context.commit("parseGoalsReactivations", res.data.company_mailings);
          context.commit("setMainLoader", false, { root: true });
        })
        .catch((error: any) => {
          context.commit("setMainLoader", false, { root: true });
          console.log(error);
        });
    },
    async getReactivationCount(context) {
      const reactivationFormData = context.state.createReactivationFromData;
      const currentSelectSegment =
        context.rootState.segments.currentSelectSegment;
      const ignoreStop = reactivationFormData.ignorePostBan ? 1 : 0;
      const trackDuring = +reactivationFormData.trackDuring;

      const ignoreActiveReactivation =
        +reactivationFormData.ignoreActiveReactivation;
      const data = {
        salon_id: context.rootState.salon_id,
        segment_id: currentSelectSegment.index,
        channel: reactivationFormData.channel,
        ignore_stop: ignoreStop,
        finish_after_days: trackDuring,
        ignore_active_reactivation: ignoreActiveReactivation,
      };
      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/count-mailing-clients",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        data: data,
      }).then((res) => {
        console.log(res);

        if (res.data.total_clients) {
          context.state.currentReactivationClientCount = res.data.total_clients
        }
      }).catch((err) => {
        console.log(err)
        context.state.currentReactivationClientCount = 0
      })
    },
    async stopWatchingReactivation(context, mailingId) {
      return await axios({
        method: "POST",
        url: "https://rfm.botzavod.com/api/finish-mailing-monitoring",
        headers: {
          Authorization: `Bearer ${context.rootState.auth.bearerToken}`,
        },
        data: {
          salon_id: context.rootState.salon_id,
          mailing_id: mailingId
        },
      }).then((data) => {
        console.log(data)
        context.dispatch('getReactivations')
      }).catch(err => {
        console.log(err)
      });
      
    }
  },
};

export default reactivationModule;
