import axios, { AxiosResponse } from "axios";
import { Module } from "vuex";

const recomednationModule: Module<any, any> = {
  namespaced: true,
  state: {
    recomendationList: {
      first: ''
    },
  },
  getters: {},
  mutations: {},
  actions: {
  },
};

export default recomednationModule;
