<template>
  <div class="segment-clients" v-if="clientsList" :class="clientPageLoading ? '_loading' : ''">
    <div class="segment-clients__row">
      <div class="segment-clients__col" v-if="false">
        <inputCheck v-model="currentPageAllClientsSelected"></inputCheck>
      </div>
      <div class="segment-clients__col">Номер</div>
      <div class="segment-clients__col">Имя</div>
      <div class="segment-clients__col">Давность</div>
      <div class="segment-clients__col">Визитов</div>
    </div>
    <div
      class="segment-clients__row"
      v-for="(client, index) in clientsList"
      :key="index"
    >
      <div class="segment-clients__col" v-if="false">
        <inputCheck
          class="segment-clients__check"
          v-model="client.selected"
          @click="clientSelect(index)"
        ></inputCheck>
      </div>
      <div class="segment-clients__col">{{ client.phone }}</div>
      <div class="segment-clients__col">{{ client.name }}</div>
      <div class="segment-clients__col">{{ client.day_count }}</div>
      <div class="segment-clients__col">{{ client.visit_count }}</div>
    </div>

    <div class="clients-pagination">
      <div class="clients-pagination__active">
        Показаны результаты с {{ minClient }} по {{ maxClient }} из
        {{ currentClientPage.total }}
      </div>

      <div class="clients-pagination__btns">
        <button class="clients-pagination__btn" @click="paginationPrev">
          Назад
        </button>
        <button
          class="clients-pagination__btn"
          @click="getStartPage"
          :class="currentPage === 1 ? '_active' : ''"
          v-show="currentPage >= 4"
        >
          1
        </button>

        <button
          class="clients-pagination__btn"
          @click="paginationListPrev"
          v-show="paginationListStep > 2"
        >
          {{'<'}}
        </button>

        <button
          class="clients-pagination__btn"
          @click="getPage(btn)"
          :class="btn === currentPage ? '_active' : ''"
          v-for="btn in startBtns"
          :key="btn"
        >
          {{ btn }}
        </button>

        <button
          class="clients-pagination__btn"
          @click="paginationListNext"
          v-show="Math.abs(endBtn - paginationListStep) > 3"
        >
          {{'>'}}
        </button>
        <button
          class="clients-pagination__btn"
          @click="getEndPage"
          :class="endBtn === currentPage ? '_active' : ''"
          v-show="endBtn"
        >
          {{ endBtn }}
        </button>

        <button class="clients-pagination__btn" @click="paginationNext">
          Вперёд
        </button>
      </div>
    </div>
  </div>
  <div
    v-else-if="
      currentClientPage.segment_clients !== undefined &&
      currentClientPage.segment_clients === false
    "
  >
    <div class="segment-clients__title">
      По заданым параметрам клиенты не найдены
    </div>
    <pre class="segment-clients__pre"
      >{{ currentClientPage ? currentClientPage : undefined }}
    </pre>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import { reactive, onMounted, computed, ref, watch } from "vue";
const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const currentClientPage = computed(
  () => store.state.segments.clients.currentClientPage
);

const clientPageLoading = computed(() => store.state.segments.clients.clientPageLoading)

const clientsList = computed(() => currentClientPage.value.segment_clients);

const allPages = computed(() => Math.ceil(+currentClientPage.value.total / 25));

const paginationListStep = ref(1);

const startBtns = computed(() => {
  if (allPages.value > 3) {
    const maxBtn =
      paginationListStep.value + 3 < allPages.value
        ? paginationListStep.value + 3
        : allPages.value;

    return [maxBtn - 3, maxBtn - 2, maxBtn - 1];
  } else {
    if (allPages.value >= 3) {
      return [1, 2, 3];
    } else if (allPages.value === 2) {
      return [1,2];
    } else if (allPages.value === 1){
      return [1];
    } else {
      return []
    }
  }
});

const endBtn = computed(() => {
  if (allPages.value > 3) {
    return allPages.value;
  } else {
    return false;
  }
});

function clientSelect(index) {
  // const currentClient = store.state.segments.clients.currentClientPage.segment_clients[index]

  // currentClient.selected = !currentClient.selected

  setTimeout(() => {
    if (
      store.state.segments.clients.currentClientPage.segment_clients.find(
        (i) => i.selected === false
      )
    ) {
      currentPageAllClientsSelected.value = false;
    } else {
      currentPageAllClientsSelected.value = true;
    }
  }, 10);
}

const currentPage = ref(1);

const currentPageAllClientsSelected = ref(false);

const currentIndex = computed(() => currentSegment.value.key === 'awaiting_segments' ? 121314 : currentSegment.value.index) 

function getPage(pageNumber) {
  currentPage.value = pageNumber;

  
  
  store.dispatch("segments/clients/getClientsForSegmentByPage", {
    segmentIndex: currentIndex.value,
    page: pageNumber,
  });
}

/* eslint-disable */
function paginationPrev() {
  if (currentPage.value > 1) {
    getPage(currentPage.value - 1);
  }
  if (currentPage.value >= 1) {
    paginationListStep.value--;
  }
}
function paginationNext() {
  if (currentPage.value !== allPages.value) {
    getPage(currentPage.value + 1);
    paginationListStep.value++;
  }
}
function paginationListNext() {
  if (paginationListStep.value !== allPages.value) {
    paginationListStep.value++;
  }
}
function paginationListPrev() {
  if (paginationListStep.value > 1) {
    paginationListStep.value--;
  }
}

function getEndPage() {
  getPage(endBtn.value)

  paginationListStep.value = endBtn.value-2
}
function getStartPage() {
  getPage(1)

  paginationListStep.value = 1
}

onMounted(() => {
  store.dispatch("segments/clients/getClientsForSegmentByPage", {
    segmentIndex: currentIndex.value,
    page: 1,
  });
});

watch(currentSegment, () => {
  store.dispatch("segments/clients/getClientsForSegmentByPage", {
    segmentIndex: currentIndex.value,
    page: 1,
  });
});

watch(currentPageAllClientsSelected, () => {
  store.state.segments.clients.currentClientPage.segment_clients.forEach(
    (client) => {
      if (currentPageAllClientsSelected.value) {
        client.selected = true;
      } else {
        if (
          !store.state.segments.clients.currentClientPage.segment_clients.find(
            (i) => i.selected === false
          )
        ) {
          store.state.segments.clients.currentClientPage.segment_clients.forEach(
            (client) => (client.selected = false)
          );
        }
      }
    }
  );
});

const minClient = computed(() => {
  if (currentPage.value === 1) {
    return "1";
  } else if (currentPage.value === allPages.value) {
    return +currentClientPage.value.total - clientsList.value.length + 1;
  } else {
    return (currentPage.value - 2) * 25 + clientsList.value.length + 1;
  }
});
const maxClient = computed(() => {
  if (currentPage.value === 1) {
    return clientsList.value.length;
  } else if (currentPage.value === allPages.value) {
    return currentClientPage.value.total;
  } else {
    return (currentPage.value - 1) * 25 + clientsList.value.length;
  }
});
</script>

<style lang="scss" scoped>
.segment-clients {
  &._loading {
    filter: blur(5px);
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    padding-top: 15px;
    margin-bottom: 10px;
  }
  &__pre {
    margin-left: 20px;
  }
  &__row {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 4fr;
    grid-gap: 10px;
    padding: 14px 24px;
    align-items: center;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: #f7f6f6;
    }

    &:first-child {
      background: #f7f9fc;
      font-weight: 500;
    }
  }
  &__col {
  }
}

.clients-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 50px;
  font-size: 16px;
  &__active {
    font-weight: 500;
  }

  &__btns {
    display: flex;
    gap: 7px;
  }

  &__btn {
    border: solid 1px #bdbdbd;
    height: 34px;
    min-width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.1s;

    &._active {
      border-color: #fbcd00;
    }

    &:first-child,
    &:last-child {
      min-width: 89px;
    }

    &:hover:not(._active) {
      background: rgba(0, 0, 0, 0.08);
    }
  }
}
</style>
