export default {
    "success": true,
    "company_rfm": {
        "total_clients": "x",
        "segment_clients": "x",
        "average_check": "x",
        "r1_level": "99.14",
        "r2_level": "538",
        "r3_level": "758",
        "r4_level": "977.03",
        "segments": {
            "without_orders": {
                "index": 0,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Клиенты ни разу не совершавшие визит"
            },
            "newcomer": {
                "index": 1,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Новички"
            },
            "growing": {
                "index": 2,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Растущие"
            },
            "champions": {
                "index": 3,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Чемпионы"
            },
            "doubting": {
                "index": 4,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Сомневающиеся"
            },
            "loyal": {
                "index": 5,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Лояльные"
            },
            "need_attention": {
                "index": 6,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Требуют внимания"
            },
            "sleeping": {
                "index": 7,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Спящие"
            },
            "on_the_brink": {
                "index": 8,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Средние на грани"
            },
            "at_risk": {
                "index": 9,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "В зоне риска"
            },
            "outflow": {
                "index": 10,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Отток за всё время"
            },
            "not_come": {
                "index": 11,
                "total_clients": "x",
                "total_clients_percent": "x",
                "average_check": "x",
                "name": "Клиент не пришел"
            }
        },
        "total_s123_clients": "x",
        "s123_percent": "x",
        "s123_average_check": "x",
        "expect_segments": {
            "total_expected_clients": "x",
            "total_expected_clients_percent": "x",
            "check": "x",
            "segments": {
                "expect_newcomer": {
                    "index": 12,
                    "total_clients": "x",
                    "total_clients_percent": "x",
                    "check": "x",
                    "name": "Ожидаем Новичка"
                },
                "expect_growing": {
                    "index": 13,
                    "total_clients": "x",
                    "total_clients_percent": "x",
                    "check": "x",
                    "name": "Ожидаем Растущего"
                },
                "expect_champion": {
                    "index": 14,
                    "total_clients": "x",
                    "total_clients_percent": "x",
                    "check": "x",
                    "name": "Ожидаем Чемпиона"
                }
            }
        }
    }
}