<template>
  <div class="reactivation-form">
    <div class="reactivation-form__block">
      <div class="reactivation-form__title">
        Получатели из сегмента "{{ activeSegment.name }}"
      </div>
      <div class="reactivation-form__subtitle text-small">
        Клиент явно дал согласие на отправку информационно-рекламной рассылки
      </div>

      <div class="reactivation-select">
        <label for="rv01" class="reactivation-select__item">
          <inputTypeRadio
            v-model="
              store.state.reactivation.createReactivationFromData
                .ignoreActiveReactivation
            "
            id="rv01"
            name="radio0"
            value="1"
          ></inputTypeRadio>
          <span
            >Исключить клиентов которые уже участвуют в активной
            Реактивации</span
          >
        </label>
        <label for="rv02" class="reactivation-select__item">
          <inputTypeRadio
            v-model="
              store.state.reactivation.createReactivationFromData
                .ignoreActiveReactivation
            "
            id="rv02"
            name="radio0"
            value="0"
          ></inputTypeRadio>
          <span>Включить в Реактивацию всех клиентов сегмента</span>
        </label>
        <label for="check1" class="reactivation-select__item _ignor">
          <inputCheck
            id="check1"
            v-model="
              store.state.reactivation.createReactivationFromData.ignorePostBan
            "
          ></inputCheck>
          <span
            >Игнорировать
            <span style="font-weight: 900; font-style: italic">несогласие</span>
            на отправку</span
          >
          <span class="text-small"
            >Отправлять тем, у кого нет согласий для отправки уведомлений</span
          >
        </label>
      </div>
    </div>
    <div class="reactivation-form__block">
      <div class="reactivation-form__title">Каналы отправки</div>
      <div class="reactivation-form__subtitle text-small">
        WhatsApp или Email
      </div>

      <div class="reactivation-select">
        <label for="rv1" class="reactivation-select__item" v-if="false">
          <inputTypeRadio
            v-model="
              store.state.reactivation.createReactivationFromData.channel
            "
            id="rv1"
            name="radio1"
            value="PUSH"
          ></inputTypeRadio>
          <span
            >Всем у кого есть мобильное приложение YPLACES, отправится
            PUSH</span
          >
        </label>
        <label for="rv2" class="reactivation-select__item">
          <inputTypeRadio
            v-model="
              store.state.reactivation.createReactivationFromData.channel
            "
            id="rv2"
            name="radio1"
            value="SMS"
          ></inputTypeRadio>
          <span>Всем отправить по WhatsApp</span>
        </label>
        <label for="rv3" class="reactivation-select__item">
          <inputTypeRadio
            v-model="
              store.state.reactivation.createReactivationFromData.channel
            "
            id="rv3"
            name="radio1"
            value="email"
          ></inputTypeRadio>
          <span>Всем у кого есть Email</span>
        </label>
      </div>
    </div>
    <div class="reactivation-form__block">
      <div class="reactivation-form__title">
        Запланировать старт Реактивации
      </div>
      <div class="reactivation-form__subtitle text-small" v-show="!errForm">
        время в часовом поясе Europe/Moscow (UTC+03)
      </div>
      <div
        class="reactivation-form__subtitle text-small text-error"
        v-show="errForm"
      >
        {{ errForm }}
      </div>

      <div class="reactivation-inputs">
        <div class="reactivation-inputs__row">
          <span class="text-small">на</span>
          <div class="reactivation-inputs__col">
            <input
              type="date"
              :min="currentDate"
              :class="{ _error: errForm }"
              v-model="
                store.state.reactivation.createReactivationFromData.startDate
              "
              class="default-input"
            />
            <span class="text-small">в</span>
            <input
              type="time"
              :class="{ _error: errForm }"
              v-model="
                store.state.reactivation.createReactivationFromData.startTime
              "
              class="default-input"
            />
          </div>
        </div>

        <div class="reactivation-inputs__row _in-develop">
          <span class="text-small">с</span>
          <div class="reactivation-inputs__col">
            <input type="time" class="default-input" />
            <span class="text-small">по</span>
            <input type="time" class="default-input" />
          </div>
          <div class="text-small reactivation-inputs__sub">
            Интервал в который разрешена рассылка (В разработке)
          </div>
        </div>
      </div>
    </div>
    <div class="reactivation-form__block _aims">
      <div class="reactivation-form__title">Считать достижением цели</div>
      <div class="reactivation-form__subtitle text-small">
        Отразится в отчете
      </div>
      <div class="fw-500 fs-12px mb-20px">
        Клиент записался / пришёл / оплатил
      </div>
      <div class="reactivation-inputs">
        <div class="reactivation-inputs__row in-develop" :class="{'_in-develop': finishAfterNextMailing}">
          <span class="text-small">Отслеживать в течении</span>
          <div class="reactivation-inputs__col">
            <input
              class="default-input _days"
              v-model="trackingDays"
              @input="validateTrackingDays"
              @blur="validateTrackingDaysBlur"
            />
            <span class="text-small">дней с момента старта Реактивации </span>
          </div>
        </div>
        <div class="reactivation-inputs__row">
          <div class="text-small reactivation-inputs__or">или</div>
        </div>
      </div>
      <div class="reactivation-select _last">
        <label for="" class="reactivation-select__item _ignor">
          <inputCheck v-model="finishAfterNextMailing"></inputCheck>
          <span>До следующей активности</span>
          <span class="text-small"
            >Вы всегда сможете завершить активность вручную в любое время</span
          >
        </label>
      </div>
    </div>
    <div class="reactivation-form__block _btns">
      <button class="reactivation-btn btn _gray" @click="prevStep">
        Назад
      </button>
      <button class="reactivation-btn btn _create" @click="createMessage" :disabled="currentReactivationClientCount === 0">
        <span v-if="currentReactivationClientCount !== 0">Создать текст сообщения</span>
        <span v-else>Рассылка невозможна</span>
        
        <span class="reactivation-btn__number"
          ><img src="~@/assets/images/letter.svg" alt="" /><span
            >{{currentReactivationClientCount}}</span
          ></span
        >
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import inputCheck from "@/components/UI/inputs/input-check.vue";
import inputTypeRadio from "@/components/UI/inputs/input-type-radio.vue";
import { computed, onMounted, onUnmounted, ref } from "vue";
import router from "@/router";

const store = useStore();

const activeSegment = computed(() => store.state.segments.currentSelectSegment);
/* eslint-disable-next-line */
const emit = defineEmits()

const rv = ref(false);
const rv1 = ref(false);
const rv2 = ref(false);

function isPastOrNearFuture(date, time) {
  const inputDateTime = new Date(`${date}T${time}:00`);
  const now = new Date();
  const fiveMinutesInMillis = 5 * 60 * 1000;

  return (
    inputDateTime <= now ||
    inputDateTime <= new Date(now.getTime() + fiveMinutesInMillis)
  );
}

const currentReactivationClientCount = computed(() => {
  return store.state.reactivation.currentReactivationClientCount;
});
function getCurrentTimePlusSixMinutes() {
  const now = new Date();
  const sixMinutesInMillis = 6 * 60 * 1000;
  const futureTime = new Date(now.getTime() + sixMinutesInMillis);

  // Округляем секунды в большую сторону
  if (futureTime.getSeconds() > 0) {
    futureTime.setMinutes(futureTime.getMinutes() + 1);
  }
  futureTime.setSeconds(0);

  // Форматируем время в "чч:мм"
  const hours = String(futureTime.getHours()).padStart(2, "0");
  const minutes = String(futureTime.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

let errForm = ref(null);

function createMessage() {
  errForm.value = isInvalideForm();
  if (!errForm.value) {
    store.state.reactivation.reactivationCreateStep = 1;
    window.scrollTo(0, 0);
  }
}
function prevStep() {
  emit('prev');
  window.scrollTo(0, 0);
  router.push("/");
}



const startDate = computed(
  () => store.state.reactivation.createReactivationFromData.startDate
);
const startTime = computed(
  () => store.state.reactivation.createReactivationFromData.startTime
);

function isInvalideForm() {
  if (!startDate.value) {
    return "Не указана дата начала рассылки";
  }

  if (!startTime.value) {
    return "Не указано время начала рассылки";
  }

  if (isPastOrNearFuture(startDate.value, startTime.value)) {
    const futureTime = getCurrentTimePlusSixMinutes();
    return `Минимальное время - сегодня ${futureTime.toLocaleString()}`;
  }

  return false;
}


const finishAfterNextMailing = computed({
  get: () => store.state.reactivation.createReactivationFromData.finishAfterNextMailing,
  set: (newValue) => {
    store.state.reactivation.createReactivationFromData.finishAfterNextMailing = newValue
  }
})

const trackingDays = computed({
  get: () => store.state.reactivation.createReactivationFromData.trackDuring,
  set: (newValue) => {
    store.state.reactivation.createReactivationFromData.trackDuring = newValue
  }
})

function validateTrackingDays() {
  trackingDays.value = trackingDays.value.replace(/\D/g, "");
  if (Number(trackingDays.value) > 365) {
    trackingDays.value = "365";
  }
}

function validateTrackingDaysBlur() {
  if (trackingDays.value < 10) {
    trackingDays.value = 10
  }
}

let currentDate = ref(null);

onMounted(() => {
  const today = new Date();
  const offset = today.getTimezoneOffset() * 60000; // смещение в миллисекундах
  const localDate = new Date(today - offset).toISOString().split("T")[0];

  currentDate.value = localDate;
});
</script>

<style lang="scss" scoped>
@import "@/assets/variables.scss";

$grid-col: 1.8fr 5fr 1fr;
.reactivation-form {
  border: solid 1px #e1e3eb;
  max-width: 1034px;
  border-radius: 12px;
  &__block {
    display: flex;
    flex-direction: column;
    padding: 40px 0;
    border-bottom: solid 1px #e1e3eb;
    display: grid;
    grid-template-columns: $grid-col;
    grid-template-rows: 25px 30px auto auto;

    .reactivation-btn {
      grid-column: 2 / 3;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 35px;
      font-weight: 500;
      justify-content: space-around;

      &._create {
        min-width: 300px;
      }

      &__number {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        gap: 2px;

        span {
          transform: translateY(1.5px);
        }
      }
    }

    &._aims {
      grid-template-rows: 25px 30px auto auto auto;

      .fw-500 {
        grid-column: 2;
      }
      .reactivation-inputs__row {
        margin-bottom: 5px;
      }
    }

    &:first-child {
      padding-bottom: 65px;
      .reactivation-select {
        &__item:last-child {
          margin-top: 20px;
        }
      }
    }
    &:nth-child(4) {
      padding-bottom: 65px;
    }

    &:last-child {
      border-bottom: none;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 21px 36px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    grid-row: 1;
    grid-column: 2 / 3;
  }

  &__subtitle {
    grid-row: 2;
    grid-column: 2 / 3;
  }

  &__btn {
    &._gray {
    }
  }
}
.reactivation-select {
  grid-row: 3;
  grid-column: 1 / -1;

  display: grid;
  grid-template-columns: $grid-col;
  grid-row-gap: 8px;

  &._last {
    grid-row: 5;
  }

  &__item {
    grid-column: 2 / -1;
    position: relative;
    display: flex;
    font-size: 14px;
    align-items: center;
    gap: 10px;
    font-weight: 500;

    &._ignor .text-small {
      position: absolute;
      bottom: -25px;
      left: 29px;
    }
  }
}
.reactivation-inputs {
  grid-row: 4;
  grid-column: 1 / -1;

  &__row {
    display: grid;
    grid-template-columns: $grid-col;
    align-items: center;

    span:first-child {
      justify-self: end;
      padding-right: 17px;
    }
  }

  &__row:first-child {
    margin-bottom: 15px;
  }

  &__col {
    display: flex;
    gap: 18px;
    align-items: center;
  }

  &__sub {
    grid-row: 2;
    grid-column: 2;

    margin-top: 8px;
  }

  &__or {
    grid-row: 2;
    grid-column: 2;
    margin-top: 12px;
    margin-bottom: 11px;
  }
}

.text-small {
  color: #71757f;
  font-size: 13px;
}

.default-input._days {
  max-width: 82px;
  text-align: center;
}

.mb-28px {
  margin-bottom: 28px;
}
</style>
