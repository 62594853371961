<template>
  <div class="reactivation-test">
    <div class="reactivation-test__inner">
      <div class="reactivation-test__title tit-h3 fw-500">
        Обязательно протестируйте шаблон
      </div>
      <div class="text-small" v-show="!error && !reactivationTestStatus">прежде чем запустить рассылку</div>
      <div class="text-small text-error" v-show="error && !reactivationTestStatus">{{error}}</div>
      <div class="text-small" style="color:rgb(55 170 145);" v-show="reactivationTestStatus">{{reactivationTestStatus}}</div>
      
      <input
      :class="error ? '_error' : ''"
        class="default-input"
        placeholder="Номер телефона тестировщика"
        v-model="reactivationTestNumber"
        type="tel"
        @input="formatPhoneNumber"
        :disabled="reactivationTestLoading"
      />

      <button
      :disabled="reactivationTestLoading"
        class="btn reactivation-test__btn"
        @click="createReactivationTest()"
      >
        <span>Тест</span>
        <div v-if="reactivationTestLoading" class="small-loader"></div>
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const reactivationTestNumber = computed({
  get: () => store.state.reactivation.reactivationTestNumber,
  set: (value: string) => {
    store.commit("reactivation/setReactivationTestNumber", value);
  },
});

function formatPhoneNumber(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, ""); // Удаляем все нечисловые символы

  if (value && value[0] !== "0") {
    value = "+" + value; // Добавляем "+" в начале, если его нет
  }

  reactivationTestNumber.value = value;
}

let error = ref('')

const reactivationTestLoading = computed(() => store.state.reactivation.reactivationTestLoading)
const reactivationTestStatus = computed({
  get: () => store.state.reactivation.reactivationTestStatus,
  set: (value:string) => store.state.reactivation.reactivationTestStatus = value
})

function createReactivationTest() {
  if (reactivationTestNumber.value.length >= 10) {
    store.dispatch('reactivation/createReactivationTest')
    error.value = ''
    reactivationTestStatus.value = ''
  } else {
    error.value = 'Некорректный номер'
    reactivationTestStatus.value = ''
  }
  
}
</script>

<style lang="scss" scoped>
.reactivation-test {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 60px;
  gap: 8px;
  background: rgba(225, 227, 235, 0.39);
  padding: 44px 34px;

  &__btn {
    position: relative;
    .small-loader {
      position: absolute;
      right: 1px;
      top: 1px;
    }
  }

  &__inner {
    display: grid;
    grid-template-columns: 363px 103px auto;
    grid-template-rows: 24px 24px 47px;
    grid-column-gap: 17px;
    grid-row-gap: 5px;
  }
  &__title {
    grid-column: 1 / -1;
  }
  .text-small {
    grid-column: 1 / -1;
  }
  .btn {
    align-self: start;
  }
  .default-input {
    font-size: 14px;
    border-radius: 12px;
  }
}
</style>
