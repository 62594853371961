export const templateAdvices = {
  title: `Структура маркетинговых сообщений, используемая самыми продвинутыми маркетологами, фокусируется на нескольких ключевых элементах для максимального вовлечения и конверсии:`,
  footerText: `<span style='font-style:italic'>Используйте эту структуру, чтобы шаг за шагом создать свой текст.</span> <br> Она поможет вам написать эффективное сообщение, которое привлечет внимание и побудит к действию.`,
  list: [
    {
      name: "Начните с простого приветствия",
      text: `Используйте имя клиента, если оно вам известно, например: "Привет, Мария!"
Приветствие должно быть дружелюбным и персонализированным, чтобы создать доверительный тон общения.
Пример: 
`,
      example: "Добрый день, Мария!",
    },
    {
      name: "Сформулируйте главное предложение (Выгода)",
      text: `В первом предложении четко обозначьте, что вы предлагаете, и в чем выгода для клиента. Это сразу привлечет внимание и заинтересует его.`,
      example:
        "Мы подготовили для вас специальную акцию на наши услуги — скидка 20% на первую запись.",
    },
    {
      name: "Добавьте подробности или объяснение",
      text: `Раскройте больше деталей, чтобы клиент понял, что именно вы предлагаете. Объясните условия, сроки или преимущества вашего предложения.
Дайте конкретные причины, почему это предложение ценно для клиента.`,
      example:
        "Скидка действует до конца этой недели, и вы можете выбрать любое время для записи.",
    },
    {
      name: "Используйте персонализацию и эмоциональный отклик",
      text: `Поделитесь историей, опытом или добавьте детали, которые могут быть важны для клиента.
Покажите, что вы понимаете их ситуацию или проблему.
`,
      example:
        "Мы знаем, как важно чувствовать себя уверенно, и хотим помочь вам выглядеть прекрасно!",
    },
    {
      name: "Завершите простым и конкретным призывом к действию",
      text: `Скажите клиенту, что он должен сделать дальше, и упростите этот шаг. Избегайте сложных или длинных призывов к действию.
Используйте фразы вроде: "Ответьте 'Да' для бронирования" или "Перейдите по ссылке, чтобы узнать больше.`,
      example:
        "Напишите 'Да', если хотите воспользоваться предложением, и я с радостью помогу вам записаться!",
    },
    {
      name: "Завершение и благодарность",
      text: `Завершите сообщение вежливым завершающим словом и поблагодарите клиента за внимание или выбор вашего бизнеса.`,
      example: "Спасибо, что выбираете нас! Мы всегда рады вам помочь.",
    },
  ],
  lastBlock: {
    name: "Итоговая структура:",
    text: `
    Приветствие: "Привет, [Имя клиента]!"<br>
    Главное предложение (выгода): "Мы предлагаем вам..."<br>
    Подробности: "Скидка действует до... и вы можете..." <br>
    Персонализация и эмоциональный отклик: "Мы знаем, как важно..."<br>
    Призыв к действию: "Напишите 'Да', чтобы..."<br>
    Благодарность: "Спасибо, что выбираете нас!"
    `,
  },
};
export const sendAdvices = {
  list: [
    {
      name: `Возраст номера`,
      text: `Используйте номер для рассылок, который подключён к WhatsApp не менее 2 месяцев`,
    },
    {
      name: `Не отправляйте ссылки`,
      text: `Избегайте включения ссылок в рассылки, это увеличивает вероятность блокировки.`,
    },
    {
      name: `Персонализация`,
      text: `Используйте переменные для персонализированных сообщений, чтобы повысить доверие клиентов.`,
    },
    {
      name: `Предупреждение о спаме`,
      text: `При первом сообщении от незнакомого номера клиент может нажать "пожаловаться на спам". Придерживайтесь корректной коммуникации.`,
    },
  ],
};
