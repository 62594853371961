<template>
  <div class="whatsapp-block">
    <div class="whatsapp-block__inner">
      <div class="whatsapp-block__instruction">
        <div class="tit-h3">
          Для отправки и приема сообщений Вам необходимо <br />
          авторизовать наш сервер как WhatsApp Web.
        </div>

        <div class="whatsapp-ul" v-if="true">
          <ul>
            <li>Откройте WhatsApp на своём телефоне</li>
            <li>
              Нажмите <span class="fw-500">Меню</span>
              <span class="whatsapp-ul__icon">
                <img :src="require('@/assets/images/dots-wa.svg')" alt="" />
              </span>
              или <span class="fw-500">Настройки</span>

              <span class="whatsapp-ul__icon">
                <img :src="require('@/assets/images/settings-wa.svg')" alt="" />
              </span>
              и выберите <br />
              <span class="fw-500">Связные устройства</span>
            </li>
            <li>
              Нажмите
              <span class="fw-500">Привязка устройства</span>
            </li>
            <li>Наведите свой экран на этот экран, чтобы считать код</li>
          </ul>
        </div>
        <div class="whatsapp-ul" v-else>
          <p class="mb-3">Чтобы сменить устройство / номер</p>
          <ul>
            <li>
              Нажмите <span class="fw-500">Меню</span>
              <span class="whatsapp-ul__icon">
                <img :src="require('@/assets/images/dots-wa.svg')" alt="" />
              </span>
              или <span class="fw-500">Настройки</span>

              <span class="whatsapp-ul__icon">
                <img :src="require('@/assets/images/settings-wa.svg')" alt="" />
              </span>
              и выберите <br />
              <span class="fw-500">Связные устройства</span>
            </li>
            <li>
              Выберите
              <span class="fw-500">привязанное устройство</span> / нажмите ВЫХОД
            </li>
            <li>после выхода перезагрузите страницу с QR кодом</li>
            <li>Привяжите новое устройство</li>
          </ul>
        </div>
      </div>

      <whatsappQr></whatsappQr>

      <p class="text">
        Внимание - приложение WhatsApp сканирует QR код дважды за доли секунды /
        не убирайте телефон от QR кода слишком быстро
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useStore } from "vuex";
import whatsappQr from "@/components/whatsapp/whatsappQr.vue";

const store = useStore();
</script>

<style lang="scss" scoped>
.whatsapp-block {
  padding: 38px;
  background: rgb(223, 223, 221);
  background: linear-gradient(
    0deg,
    rgba(223, 223, 221, 0.9) 80%,
    rgba(74, 162, 131, 0.9) 80%
  );
  border-radius: 10px;

  &__inner {
    background: #fff;
    filter: drop-shadow(6px 8px 12px rgba(0, 0, 0, 0.25));
    border-radius: 4px;
    padding: 40px 70px 40px 40px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 50px;
  }

  &__instruction {
    padding-top: 10px;

    display: flex;
    flex-direction: column;
    gap: 50px;

    .tit-h3 {
      line-height: 30px;
    }
  }
}

.whatsapp-ul ul {
  display: flex;
  flex-direction: column;
  gap: 15px;

  font-size: 18px;
}
.whatsapp-ul__item {
  display: flex;
}

.whatsapp-ul__num {
  display: block;
  font-weight: 600;
  margin-right: 10px;
}
.whatsapp-ul__icon img {
  opacity: 0.3;
  width: 16px;
  height: 16px;
}
.whatsapp-ul__icon {
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: #f2f2f2;
  padding: 3px;
  border-radius: 5px;
  margin: 5px;
}

.whatsapp-ul__link a {
  width: 100%;
  height: 100%;
}

.whatsapp-ul ul li {
  list-style-type: numeric;
}
.whatsapp-ul ul {
  padding-left: 20px;
}
</style>
