<template>
  <div class="reactivation-page">
    <reactivationList v-if="!showSegmentSettings"></reactivationList>
    <reactivationCreate v-else></reactivationCreate>
  </div>
</template>

<script setup>
import reactivationList from '@/components/reactivation/reactivationList.vue'
import reactivationCreate from '@/components/reactivation/reactivationCreate.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from 'vue'

const store = useStore()
const showSegmentSettings = computed(() => store.state.segments.showSegmentSettings)

onMounted(() => {
  store.dispatch('reactivation/getReactivations')
})
</script>

<style>

</style>